import React from "react"
import { Link } from "react-router-dom";
import ColoredLine from '../Components/ColoredLine'
import {store} from '../store'

export default function About() {
  console.log(">>About rendered")
  return (
    <>
      <div style={{textAlign:'left'}}>
        <p>ABOUT THIS APP</p>
        <p>{store.about}</p>    
      </div>
      <ColoredLine color="teal" />
        <Link className="btn primary" to='/'>Home</Link>
      <ColoredLine color="teal" />
    </  >
  );
}
