import React  from 'react';
import { Switch, Route, Link } from "react-router-dom";
import './App.css';

import {store, useStore} from './store'

import Loader from './Components/Loader'
import Home from './Pages/Home'
import About from './Pages/About'



const routes = [ 
  {route: Home, path:'/', label:'Home'},
  {route: About, path:'/about', label:'About'},
]



const App = () => {
  console.log('>>App renderered')
  useStore()

  return (
    <div className="App">
      
      { store.loading && <Loader color="teal" height={70} width={70}/> }  


      <div className="App-header">
        <img src="./logo192.png" alt="logo" width='40' height='40' />
        <div style={{verticalAlign: 'middle', lineHeight: '40px', paddingLeft: '5px'}}>CalBal <i><small>react</small></i></div>
        <Link style={{flexGrow:'1', textAlign:'right', alignSelf:'center', color:'white'}} to='/about'>?</Link>
      </div>

      <div className="App-body">
        <Switch>
            {routes.map( (r, k) => 
            <Route exact path={r.path} component={r.route} key={'r'+k}/>
          )}
        </Switch>            
      </div>
    </div>
  );
}

export default App
