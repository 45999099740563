import { useState } from "react";

// NOTA BENE: 
// utilizzare con parsimonia le modifiche allo state (methods) in quanto forzano un rerender dell'intero TREE
// possibile mitigare usando i React.memo() per condizionare il rerender del component
// nessun problema invece in fase di lettura (getters)

let store = {}

const initialState = {
  loading: false,
  query: {},
  updating: null,
  about: 'Lorem ipsum dolor sit amet, consectetur adipisci elit ...'
}

function useStore() { //init the Store
  console.log('___access store')
  const [state, setState] = useState(initialState);

  // list state fields you want to make visible
  // to access use store.field
  const getters = {
    loading: state.loading,
    query: state.query,
    updating: state.updating,
    about: state.about
  }

  // list state modifiers, use setState for reactivity
  // to access use store.method()
  const methods = {
    set(key, value) { setState({...state, [key]: value, updating: key}) }, 
    setLoading(flag) { this.set('loading',flag) }
  }

  store = {
    ...getters,
    ...methods
  }
}

export {useStore, store}