import React, { useState, useRef, useEffect }  from 'react';
//import logo from './logo.svg';
import axios from 'axios'
import Chartjs from 'chart.js'

import ColoredLine from '../Components/ColoredLine'

import {store} from '../store'

const params = [
  {key: 'pizze', label: 'n. panetti', default: '4' },
  {key: 'peso', label: 'peso panetto (g)',  default: '250' },
  {key: 'idratazione', label: 'idratazione (% farina)', default: '65' },
  {key: 'sale', label: 'sale (% farina)', default: '4.5' },
  {key: 'olio', label: 'olio (% farina)', default: '0'},
  {key: 'liev_tot', label: 'tot lievitazione (ore)', default: '24' },
  {key: 'liev_frigo', label: 'di cui frigo (ore)', default: '21' },
  {key: 'ta', label: 'temp ambiente (°C)', default: '28' },
  {key: 'tc', label: 'temp frigo (°C)', default: '6' },
]

const Home = () => {
  console.log('>>Home renderered')

  const [query, setQuery] = useState({pNapol:1})
  const [dosi, setDosi] = useState(null)
  const [fasi, setFasi] = useState(null)

  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [showchart, setShowChart] = useState(null)



  const resetQuery = () => {
    console.log('resetQuery...')
    let reset = {pNapol:1}
    params.map( x => reset[x.key]=x.default )
    setQuery(reset)
    setDosi(null)
    setFasi(null)
    setShowChart(null)
  }

  const validateQuery = () => {
    let flag = true
    params.forEach((p)=>{
      if(!query[p.key]) flag = false
    })
    return flag
  }

  const sendQuery = () => {
    console.log('sendQuery...')
    if(!validateQuery()) return null

    //https://calbal.altervista.org/calcola.php?impBase=1&lievitoLdb=1&pNapol=1&percAcqua=0&LM=0&BIGA=0&tipoLdb=1&text1=2&text2=250&text3=65&textA=4.5&textB=0&textC=24&textD=21&text4=30&text5=7
    const uri = `https://dadda.net/cors/https://calbal.altervista.org/calcola.php?impBase=1&lievitoLdb=1&pNapol=${query.pNapol}&percAcqua=0&LM=0&BIGA=0&tipoLdb=1`+
                `&text1=${query.pizze}&text2=${query.peso}&text3=${query.idratazione}&textA=${query.sale}&textB=${query.olio}&textC=${query.liev_tot}&textD=${query.liev_frigo}&text4=${query.ta}&text5=${query.tc}`
    
    console.log('show loading...')
    store.setLoading(true)
    axios({method:'GET', timeout: 5000, url: encodeURI(uri), data: null, headers: { }})
      .then((resp)=>{
        console.log('hide loading...')
        store.setLoading(false)
        const r = resp.data.split('-')

        const impasto_staglio = r.splice(-9,9)
        const staglio = impasto_staglio[4] //staglio hour
        console.log('fasi...')

        setFasi(
            query.liev_frigo !== '0'
            ? `Impasto e riposo 1 ora a t.a. - Frigo per ${query.liev_frigo} ore - Staglio all'ora ${staglio} - Appretto di ${Math.floor(Number.parseFloat(query.liev_tot-staglio)*100)/100} ore`
            : `Impasto e puntata di ${staglio} ore a t.a. - Staglio all'ora ${staglio} - Appretto di ${Math.floor(Number.parseFloat(query.liev_tot-staglio)*100)/100} ore`
        )
        const ricetta = r.splice(0,28)
        console.log('dosi...')
        setDosi({
          farina: ricetta[1], acqua: ricetta[2], sale: ricetta[3], olio: ricetta[4], 
          'ldbf (ldbs)': ricetta[5]+' ('+Number.parseFloat(ricetta[5]/3).toFixed(2)+')', peso_tot: ricetta[6], //Wcons: r[8]
        })

        //parse graph data
        let datachart = []
        //horrible hack to reach actual start of the curve
        while(r.length && (r[0]!=='0' && r[1]!=='24')) r.splice(0,2)
        datachart.push({x:r[0],y:r[1]})
        r.splice(0,2)
        for (var n=0; n<r.length; n=n+4) {
          datachart.push({x:r[n],y:r[n+1]})
        }

        loadChart(datachart)

        //save query in store; use global state as little as possible as it will render whole App tree
        store.set('query',query)
      })
      .catch((err)=>{
        store.setLoading(false)
        if(err.response && err.response.data) err=err.response.data
        console.log(err)
        alert(err)
      })
  }

  function loadChart(data) {
    console.log('loadChart...')
    if (chartInstance) {
      console.log('...updating')
      chartInstance.data.datasets[0].data = data
      chartInstance.update()
      setShowChart(true)
    } 
    else if (chartContainer && chartContainer.current) {
      console.log('...creating')
      const newChartInstance = new Chartjs(chartContainer.current, {
          type: 'line',
          data: {
            datasets: [{
              data: data,
              //label: 'curva lievitazione',
              borderColor: "teal",
              backgroundColor: "white",
            }] 
          },
          options: {
            scales: {
              xAxes: [{
                type: 'linear',
                position: 'bottom'
              }]
            },
            legend: { display: false}
          }
      });
      setChartInstance(newChartInstance)
      setShowChart(true)
    }
  }

  useEffect(() => {
    console.log('Home loaded')
    if(Object.keys(store.query).length) setQuery(store.query)
    return () => console.log('Home unloaded') 
  }, []);


  return (
    <>
        <ColoredLine color="teal" />
          <label><input type='radio' name='pNapol' checked={query.pNapol === 1} onChange={() => setQuery({...query, pNapol:1})}/><span/><span>Napoletana</span></label>
          <label><input type='radio' name='pNapol' checked={query.pNapol === 0} onChange={() => setQuery({...query, pNapol:0})}/><span/>Teglia o Pala</label>
        <ColoredLine color="teal" />

        { params.map((p,k)=>
          <React.Fragment key={k}>
            <div style={{width:'70%', textAlign:'left'}} >{p.label}</div>
            <input style={{width:'22%', textAlign:'center', fontSize:'100%'}} type="number" inputMode="decimal" value={query[p.key] || ''} onChange={(e)=> setQuery({...query, [p.key]:e.target.value})} placeholder=' '/>
          </React.Fragment>
        )}    

        <ColoredLine color="teal" />
          <button className="btn primary" disabled={!validateQuery() ? 'disabled' : null} onClick={() => sendQuery()}>{!validateQuery() ? 'Compila tutti i campi' : 'Calcola dosi e tempi'}</button>
          <button className="btn secondary" onClick={() => resetQuery()}>Load defaults</button>
        <ColoredLine color="teal" />

        {dosi && Object.keys(dosi).map((x,i)=> 
          <React.Fragment key={x}>
            <div style={{width:'70%', textAlign:'left'}}>{x}</div>
            <div style={{width:'25%', textAlign:'center'}} >{dosi[x]}</div>
          </React.Fragment>
        )}
        {dosi && <ColoredLine color="teal" />}
        {fasi && <div>{fasi}</div>}
        
        <div style={{display: showchart ? null: 'none', width: '100%'}}>
          <canvas ref={chartContainer} />
        </div>


    </>
  );
}

export default React.memo(Home,(old,ne)=>{
  return store.updating !== 'query' //don't update in this case
});
